


















































































































import { KlevuEvents } from '@klevu/core';
import type { PropType } from '@nuxtjs/composition-api';
import { defineComponent, toRefs, useContext } from '@nuxtjs/composition-api';

import SkeletonLoader from '~/components/SkeletonLoader/index.vue';
import { useImage } from '~/composables';
import VaimoProductCard from '~/diptyqueTheme/components/organisms/VaimoProductCard.vue';
import SectionsList from '~/diptyqueTheme/components/templates/SectionsList.vue';
import {
  useGoogleTagManager,
  useRequestPath
} from '~/diptyqueTheme/composable';
import { validateFraction } from '~/diptyqueTheme/helpers/priceFormatter';
import customProductGetters from '~/diptyqueTheme/helpers/productGetters';
import { useAddToCart } from '~/helpers/cart/addToCart';
import { useQuickSearch } from '~/integrations/klevu-search/index';
import { useProductsWithCommonProductCardProps } from '~/modules/catalog/category/components/views/useProductsWithCommonCardProps';
import productGetters from '~/modules/catalog/product/getters/productGetters';
import type { Product } from '~/modules/catalog/product/types';
import type { CategoryTree } from '~/modules/GraphQL/types';

export default defineComponent({
  components: {
    VaimoProductCard,
    SkeletonLoader,
    SectionsList
  },
  props: {
    products: {
      type: Array as PropType<Product[]>,
      required: true
    },
    pricesLoaded: Boolean,
    loading: Boolean,
    isUsedForKlevuSearchResults: {
      type: Boolean,
      default: false,
      required: false
    },
    activeCategoryData: {
      required: false,
      type: Object as PropType<CategoryTree>,
      default: () => ({})
    }
  },
  emits: ['click:wishlist', 'click:add-to-cart'],
  setup(props) {
    const { app } = useContext();
    const { getSelectItemDetails } = useGoogleTagManager();
    const onGtmSelectItem = (product: Product, index: number, event): void => {
      // Prevents GTM event from firing when clicking on the add to cart button
      if (event.target.closest('.vaimo-product-card__add-to-cart')) {
        return;
      }

      app.$gtm.push(getSelectItemDetails(product, index, 'plp'));
    };
    const {
      imageSizes: { productCard: imageSize }
    } = useImage();
    const { getRelatedProductUrl } = useRequestPath();
    const { products } = toRefs(props);
    const { productsWithCommonProductCardProps } =
      useProductsWithCommonProductCardProps(products);
    const { getMagentoImage } = useImage();
    const { isInCart } = useAddToCart();
    const getProductLink = (product: Product): string =>
      getRelatedProductUrl(product);
    const quickSearchStore = useQuickSearch();

    const productClick = (product) => {
      if (props.isUsedForKlevuSearchResults) {
        KlevuEvents.searchProductClick(product, quickSearchStore.searchTerm);
      }
    };

    const contentTypeClass = (typename) => {
      const classMap = {
        ProductBanner: 'grid-item__product-banner',
        EcomPush: 'grid-item__ecom-push',
        EditorialBanner: 'grid-item__editorial-banner',
        EditorialCard: 'grid-item__editorial-card',
        ProductPush: 'grid-item__product-push'
      };
      return classMap[typename] || '';
    };

    const getBasePrice = (product) => {
      return validateFraction(
        product.price_type === 'DYNAMIC'
          ? product.custom_set_minimal_price_excluded_tax
          : customProductGetters.getProductBasePrice(product)
      );
    };

    return {
      imageSize,
      productsWithCommonProductCardProps,
      getMagentoImage,
      productGetters,
      customProductGetters,
      getProductLink,
      isInCart,
      contentTypeClass,
      onGtmSelectItem,
      validateFraction,
      productClick,
      getBasePrice
    };
  }
});
